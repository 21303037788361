<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view/>
    <FooterBottom v-if="!$route.meta.flag"></FooterBottom>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  data(){
return{
  isMobile: false
}
  },
  // components: {
  //   HelloWorld
  // }
  created(){
    this.checkMobile()
  },
  methods:{
    checkMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      if(this.isMobile){
        window.location.href = "https://h5.liangzhidao.com/#/"
      }
    }
  }
}
</script>

<style>
.messageBoxTip{
    z-index: 999999999 !important;
}
/* .el-switch__core{
  width:0.8rem!important;
  height: 0.3rem!important;
  border-radius: 0.5rem!important;
}
.el-switch__core:after{
  width: 0.18rem!important;
  height: 0.18rem!important;
  top: 0.05rem!important;
}
.el-switch.is-checked .el-switch__core::after{
  margin-left: -0.2rem!important;
}
.el-date-editor .el-input__icon {
  display: none!important;
}

.el-input__icon{
  width: 0.25rem!important;
  display: flex!important;
  align-items: center!important;
}
.el-input{
 font-size: 0.3rem!important;
display: flex!important;
align-items: center!important;
justify-content: space-between!important;
}
.el-input__inner{
  padding-left: 0.15rem!important;

  width: 7.63rem!important;
height: 0.64rem!important;
border-radius: 0.16rem!important;
border: 0.01rem solid #ced9f2!important;
  color: #253a70!important;
 font-size: 0.3rem!important;
 font-weight: 400!important;
 font-family: "SourceHanSansCN"!important;
}
.el-textarea__inner{
  padding-left: 0.15rem!important;

  width: 7.63rem!important;
min-height: 0.64rem!important;
border-radius: 0.16rem!important;
border: 0.01rem solid #ced9f2!important;
  color: #253a70!important;
 font-size: 0.3rem!important;
 font-weight: 400!important;
 font-family: "SourceHanSansCN"!important;
}
.el-form-item__error {
		left: 3.2rem !important;
    font-size: 0.2rem!important;
	}
.el-form-item__content {
  margin-left: 0 !important;
} */
 input{
  letter-spacing: 1px;
 }
.el-form-item__error {
		left: 4.7rem !important;
    /* font-size: 0.2rem!important; */
	}
#app{
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;

}
html,body{
  margin: 0;
  /* padding-bottom:2.96rem ; */
  /* padding-bottom:3.5rem ; */
height: 100%;



}
input{
  background: none;
border: none;

}
input:focus {
outline: none;
}
button{
  border: none;
  background: none;
  padding: 0;
}
.bgColor {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background:url('@/assets/bgColor.png') no-repeat center;
    background-size: 100% 100%;
		z-index: -1;
	}
  /* 自定义指示器样式 */  
.el-carousel__indicator.is-active button {  
  width: 0.12rem;
height: 0.12rem;
opacity: 1;
background: #00b34a;
border-radius: 50%;

}  
  
.el-carousel__indicators--outside button {  
  width: 0.12rem;
  height: 0.12rem;
opacity: 1;
background: #dddddd;
border-radius: 50%;
}
</style>
